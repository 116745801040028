import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Images
import iconPDF from "../../../images/icon/whatsapp.png";
import iconDOC from "../../../images/icon/book.png";
import iconDOC1 from "../../../images/icon/callus.png";

function WidgetBrochure() {
  const { t } = useTranslation();

  return (
    <>
      <div className="widget">
        <div className="brochure-bx">
          <h5 className="title-head">{t('common.quicklink')}</h5>
          <a
            target="_blank"
            href="https://wa.me/+966557835647?text=Hi%20Swiss%20Auto%20%2C%20%0AI%20would%20like%20to%20know%20more%20about%20your%20products"
            className="download-link"
          >
            <img src={iconPDF} alt="" />
            <h5 className="title">{t('common.whatsapp')}</h5>
            <span dir="ltr">+966 55 783 5647</span>
          </a>
          <a
            target="_blank"
            href="tel:+966 55 783 5647
"
            className="download-link"
          >
            <img src={iconDOC1} alt="" />
            <h5 className="title">{t('common.callnow')}</h5>
            <span dir="ltr">+966 55 783 5647</span>
          </a>
          <Link to="/booking" className="download-link">
            <img src={iconDOC} alt="" />
            <h5 className="title">{t('common.callback')}</h5>
            <span>{t('common.onehour')}</span>
          </Link>
        </div>
      </div>
    </>
  );
}

export default WidgetBrochure;
