import React, { useRef, useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';

// Layout
//import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import Header from "../layout/header";
import ChooseUsSection from "../elements/choose-us";
import ScheduleServiceSection from "../elements/schedule-service";
import TeamOneSection from "../elements/team-1";
import TestimonialSection from "../elements/testimonial";
import { useTranslation } from 'react-i18next';
import HeroBanner from '../elements/widget/HeroBanner';
import InstagramBox from '../elements/widget/InstagramBox';

import bg2 from "../../images/background/bg2.png";
import bnrImg from "../../images/newimages/package-banner.webp";
import axios from 'axios';
import { Helmet } from 'react-helmet';
function ServicesBox() {


	const { t } = useTranslation();

	// const handleRefresh = (url) => {
	// 	window.location.href = url;
	// };
	
	const myRef = useRef();

  function scrollToComponent() {
    if (window.location.hash === '#s1') {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect( () => scrollToComponent(), [] )
		
		return (
			<>
			
				<Header />
				
				<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>{t('common.ourservices')}</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>{t('header-area.home')}</Link></li>
										<li className="breadcrumb-item active" aria-current="page">{t('common.ourservices')}</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				
				
				
				<section id="s1" ref={myRef} className="section-area bg-primary service-wraper1 section-sp2" style={{backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat", backgroundPosition: "right top"}}>
					<div className="container">
						<div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
							<div className="clearfix">
								<h6 className="title-ext">{t('common.ourservices')}</h6>
								<h2 className="title mb-0">{t('servicespage.headtitle1')}</h2>
							</div>
						</div>
						<div className="row spno">
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
										<span className='serviceicon service1'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.axle')}</h5>
										<p>{t('servicespage.axletext')}</p>
										<Link to="/services/mechanical-repair/axle-drift-shaft-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service2'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.brake')}</h5>
										<p>{t('servicespage.braketext')}</p>
										<Link to="/services/mechanical-repair/brake-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service3'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.engilecool')}</h5>
										<p>{t('servicespage.engilecooltext')}</p>
										<Link to="/services/mechanical-repair/engine-cooling-system-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service4'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.gearbox')}</h5>
										<p>{t('servicespage.gearboxtext')}</p>
										<Link to="/services/mechanical-repair/gear-box-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service5'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.prepurchase')}</h5>
										<p>{t('servicespage.prepurchasetext')}</p>
										<Link to="/services/mechanical-repair/pre-purchase-inspection" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service6'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.suspension')}</h5>
										<p>{t('servicespage.suspensiontext')}</p>
										<Link to="/services/mechanical-repair/suspension-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container active feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service7'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.transmission')}</h5>
										<p>{t('servicespage.transmissiontext')}</p>
										<Link to="/services/mechanical-repair/transmission-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service8'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.touchless')}</h5>
										<p>{t('servicespage.touchlesstext')}</p>
										<Link to="/services/mechanical-repair/touchless-wheel-alignment" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section id="s2" className="section-area bg-primary service-wraper1 section-sp2" style={{backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat", backgroundPosition: "right top"}}>
					<div className="container">
						<div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
							<div className="clearfix">
								<h6 className="title-ext">{t('common.ourservices')}</h6>
								<h2 className="title mb-0">{t('servicespage.headtitle2')}</h2>
							</div>
						</div>
						<div className="row spno">
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service15'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.smartbody')}</h5>
										<p>{t('servicespage.smartbodytext')}</p>
										<Link to="/services/body-shop/smart-body-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service16'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.cardetail')}</h5>
										<p>{t('servicespage.cardetailtext')}</p>
										<Link to="/services/body-shop/car-detailing" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service17'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.cartint')}</h5>
										<p>{t('servicespage.cartinttext')}</p>
										<Link to="/services/body-shop/car-tinting" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service18'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.cardip')}</h5>
										<p>{t('servicespage.cardiptext')}</p>
										<Link to="/services/body-shop/car-dip-color" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service19'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.ceramic')}</h5>
										<p>{t('servicespage.ceramictext')}</p>
										<Link to="/services/body-shop/ceramic-coating" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service20'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.brakecaliper')}</h5>
										<p>{t('servicespage.brakecalipertext')}</p>
										<Link to="/services/body-shop/brake-caliper-painting" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container active feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service21'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.paint')}</h5>
										<p>{t('servicespage.painttext')}</p>
										<Link to="/services/body-shop/paint-protection-film" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service22'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.windshield')}</h5>
										<p>{t('servicespage.windshieldtext')}</p>
										<Link to="/services/body-shop/wind-shield-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							{/* <div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service23'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">Classic Car Restoration</h5>
										<p>There are many variations of passages of Lorem Ipsum available.</p>
										<Link to="/services/body-shop/classic-car-restoration" className="btn-link">Read More</Link>
									</div>
								</div>
							</div> */}
							
						</div>
					</div>
				</section>
                <section id="s3" className="section-area bg-primary service-wraper1 section-sp2" style={{backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat", backgroundPosition: "right top"}}>
					<div className="container">
						<div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
							<div className="clearfix">
								<h6 className="title-ext">{t('common.ourservices')}</h6>
								<h2 className="title mb-0">{t('servicespage.headtitle3')}</h2>
							</div>
						</div>
						<div className="row spno">
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service8'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.acrepair')}</h5>
										<p>{t('servicespage.acrepairtext')}</p>
										<Link to="/services/electrical-repair/ac-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service9'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.camera')}</h5>
										<p>{t('servicespage.cameratext')}</p>
										<Link to="/services/electrical-repair/camera-radar-calibration" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service10'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.carsoft')}</h5>
										<p>{t('servicespage.carsofttext')}</p>
										<Link to="/services/electrical-repair/car-software-repair-programming" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service11'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.comfort')}</h5>
										<p>{t('servicespage.comforttext')}</p>
										<Link to="/services/electrical-repair/comfort-system-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service12'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.enginecontrol')}</h5>
										<p>{t('servicespage.enginecontroltext')}</p>
										<Link to="/services/electrical-repair/engine-control-unit-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							{/* <div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service13'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">Navigation Update</h5>
										<p>The engine control unit has greater responsibility like determining the performance of your vehicle and ensuring a safer ride.

The problems with the engine control unit may arrive due to various reasons but regularly inspecting the engine control unit of the car can save you both time and money. The engine control unit of the car is highly intricate and it is always good to rely on experienced technicians to handle it.</p>
										<Link to="/services/electrical-repair/navigation-update" className="btn-link">Read More</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container active feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service14'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">Retrofit</h5>
										<p>There are many variations of passages of Lorem Ipsum available.</p>
										<Link to="/services/electrical-repair/retrofit" className="btn-link">Read More</Link>
									</div>
								</div>
							</div> */}
						</div>
					</div>
				</section>
                
                <section id="s4" className="section-area bg-primary service-wraper1 section-sp2" style={{backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat", backgroundPosition: "right top"}}>
					<div className="container">
						<div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
							<div className="clearfix">
								<h6 className="title-ext">{t('common.ourservices')}</h6>
								<h2 className="title mb-0">{t('servicespage.headtitle4')}</h2>
							</div>
						</div>
						<div className="row spno">
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
										<span className='serviceicon service24'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.carrecovery')}</h5>
										<p>{t('servicespage.carrecoverytext')}</p>
										<Link to="/services/other-services/car-recovery" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service25'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.fleet')}</h5>
										<p>{t('servicespage.fleettext')}</p>
										<Link to="/services/other-services/fleet-maintenance" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
							
							<div className="col-xl-3 col-lg-4 col-md-6">
								<div className="feature-container feature-bx1 mb-sm-30">
									<div className="icon-lg mb-10 text-primary">
									<span className='serviceicon service26'>&nbsp;</span>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte text-uppercase">{t('servicespage.electric')}</h5>
										<p>{t('servicespage.electrictext')}</p>
										<Link to="/services/other-services/electric-car-repair" className="btn-link">{t('servicespage.readmore')}</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				
				<ChooseUsSection />
				<TestimonialSection />
				<ScheduleServiceSection />
				<InstagramBox/>
				
				<Footer />
				
			</>
		);
}

export default ServicesBox;