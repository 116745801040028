import { useState, useEffect } from 'react';
import axios from 'axios';
import i18next from 'i18next';

const useFetchServicesInfo = (slug) => {
  const [pdata, setpData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const endPoint = "https://cms.swissauto.me/graphql";
  const headers = {
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const currentLanguage = i18next.language;
        const query = `
          query {
            serviceBy(slug: "${slug}") {
              translation(language: ${currentLanguage.toUpperCase()}) {
                title
                content
                seo {
                  title
                  metaDesc
                }
              }
            }
          }`;

        const response = await axios.get(`${endPoint}?query=${encodeURIComponent(query)}`, {
          headers,
        });

        setpData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    if (slug) fetchPageData();
  }, [slug]);

  return { pdata, loading, error };
};

export default useFetchServicesInfo;