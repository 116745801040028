import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Images
import bg2 from "../../images/background/bg2.png";

function ServicesSection() {
  const { t } = useTranslation();

  return (
    <>
      <section
        className="section-area bg-primary service-wraper1 section-sp2"
        id="secBS"
        style={{
          backgroundImage: "url(" + bg2 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
        }}
      >
        <div className="container">
          <div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
            <div className="clearfix">
              <h6 className="title-ext">{t("common.ourservices")}</h6>
              <h2 className="title mb-0">{t("common.servicesSubtext")}</h2>
            </div>
            <div className="clearfix mt-md-20">
              <Link to="/services" className="btn btn-outline-light">
                {t("common.servicesbtn")}
              </Link>
            </div>
          </div>
          <div className="row spno">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
                <div className="icon-lg mb-10 text-primary">
                  <span className="serviceicon service1">&nbsp;</span>
                </div>
                <div className="icon-content">
                  <h5 className="ttr-tilte text-uppercase">
                    {t("servicespage.axle")}
                  </h5>
                  <p>{t("servicespage.axletext")}</p>
                  <Link
                    to="/services/mechanical-repair/axle-drift-shaft-repair"
                    className="btn-link"
                  >
                    {t("servicespage.readmore")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="feature-container feature-bx1 mb-sm-30">
                <div className="icon-lg mb-10 text-primary">
                  <span className="serviceicon service2">&nbsp;</span>
                </div>
                <div className="icon-content">
                  <h5 className="ttr-tilte text-uppercase">
                    {t("servicespage.brake")}
                  </h5>
                  <p>{t("servicespage.braketext")}</p>
                  <Link
                    to="/services/mechanical-repair/brake-repair"
                    className="btn-link"
                  >
                    {t("servicespage.readmore")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
                <div className="icon-lg mb-10 text-primary">
                  <span className="serviceicon service3">&nbsp;</span>
                </div>
                <div className="icon-content">
                  <h5 className="ttr-tilte text-uppercase">
                    {t("servicespage.engilecool")}
                  </h5>
                  <p>{t("servicespage.engilecooltext")}</p>
                  <Link
                    to="/services/mechanical-repair/engine-cooling-system-repair"
                    className="btn-link"
                  >
                    {t("servicespage.readmore")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="feature-container feature-bx1 mb-sm-30">
                <div className="icon-lg mb-10 text-primary">
                  <span className="serviceicon service4">&nbsp;</span>
                </div>
                <div className="icon-content">
                  <h5 className="ttr-tilte text-uppercase">
                    {t("servicespage.gearbox")}
                  </h5>
                  <p>{t("servicespage.gearboxtext")}</p>
                  <Link
                    to="/services/mechanical-repair/gear-box-repair"
                    className="btn-link"
                  >
                    {t("servicespage.readmore")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="feature-container feature-bx1 mb-sm-30">
                <div className="icon-lg mb-10 text-primary">
                  <span className="serviceicon service5">&nbsp;</span>
                </div>
                <div className="icon-content">
                  <h5 className="ttr-tilte text-uppercase">
                    {t("servicespage.prepurchase")}
                  </h5>
                  <p>{t("servicespage.prepurchasetext")}</p>
                  <Link
                    to="/services/mechanical-repair/pre-purchase-inspection"
                    className="btn-link"
                  >
                    {t("servicespage.readmore")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
                <div className="icon-lg mb-10 text-primary">
                  <span className="serviceicon service6">&nbsp;</span>
                </div>
                <div className="icon-content">
                  <h5 className="ttr-tilte text-uppercase">
                    {t("servicespage.suspension")}
                  </h5>
                  <p>{t("servicespage.suspensiontext")}</p>
                  <Link
                    to="/services/mechanical-repair/suspension-repair"
                    className="btn-link"
                  >
                    {t("servicespage.readmore")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="feature-container active feature-bx1 mb-sm-30">
                <div className="icon-lg mb-10 text-primary">
                  <span className="serviceicon service7">&nbsp;</span>
                </div>
                <div className="icon-content">
                  <h5 className="ttr-tilte text-uppercase">
                    {t("servicespage.transmission")}
                  </h5>
                  <p>{t("servicespage.transmissiontext")}</p>
                  <Link
                    to="/services/mechanical-repair/transmission-repair"
                    className="btn-link"
                  >
                    {t("servicespage.readmore")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="feature-container bg-gray-alt feature-bx1">
                <div className="icon-lg mb-10 text-primary">
                  <span className="serviceicon service8">&nbsp;</span>
                </div>
                <div className="icon-content">
                  <h5 className="ttr-tilte text-uppercase">
                    {t("servicespage.touchless")}
                  </h5>
                  <p>{t("servicespage.touchlesstext")}</p>
                  <Link
                    to="/services/mechanical-repair/touchless-wheel-alignment"
                    className="btn-link"
                  >
                    {t("servicespage.readmore")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServicesSection;
