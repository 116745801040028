import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import parse from 'html-react-parser';
import useFetchBrandInfo from "../../lib/useFetchBrandInfo";
// Layout
import Header from "../../layout/header";
import Footer from "../../layout/footer";

// Elements
import WidgetBrochure from "../../elements/widget/widget-brochure";
import WidgetHelp from "../../elements/widget/widget-help";
import ScheduleServiceSection from "../../elements/schedule-service";
import TestimonialSection from "../../elements/testimonial";
import InstagramBox from "../../elements/widget/InstagramBox";
import OurBrandsLayout from "../../elements/widget/OurBrandsLayout";
import BrandServices from "../../elements/widget/BrandServices";

// Images
import bnrImg from "../../../images/carbrands/Maserati.webp";
import serviceSectionPic3 from "../../../images/brandsthumb/MASERATI body Image.webp";

function Maserati() {
  const { t } = useTranslation();
  
  const { pdata, loading, error } = useFetchBrandInfo('maserati');

  const titleText = pdata?.data?.brandBy?.translation?.title || t("brandmaserati.headtitle");
  const imageUrl = pdata?.data?.brandBy?.translation?.featuredImage?.node?.mediaItemUrl || serviceSectionPic3;
  const altText = pdata?.data?.brandBy?.translation?.featuredImage?.node?.altText || t("brandmaserati.name");
  const benefitsText = pdata?.data?.brandBy?.translation?.benefits?.benefits || '<div className=\"col-xl-6\"><\/div>';
  const contentText = pdata?.data?.brandBy?.translation?.content || '<div className=\"mb-10 ContentBox2\"><\/div>';
  const metaText = pdata?.data?.brandBy?.translation?.seo?.title || '';
  const metaDesc= pdata?.data?.brandBy?.translation?.seo?.metaDesc || '';

  if (loading) return (<><div className="loader-container"></div></>);
  if (error) return <p>Error: {error.message}</p>;
  
  return (
    <>
      <Header seotitle={metaText} seodesc={metaDesc} />

      <div className="page-content bg-white">
        <div
          className="page-banner"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
              <span>{titleText}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="las la-home"></i>
                      {t("header-area.home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/ourbrands">{t("header-area.brands")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("brandmaserati.name")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="section-area section-sp2 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <aside className="sticky-top pb-1">
                  <WidgetBrochure />

                  <WidgetHelp />
                </aside>
              </div>
              
              <div className="col-lg-8 col-md-7">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="ttr-media mb-30">
                          {/* Image/Alt Text */}
                          <img src={imageUrl} alt={altText} />
                      </div>
                    </div>
                    
                    {/* Benefits */}
                    {parse(benefitsText)}
                    
                  </div>

                  {/* Main Content */}
                  {parse(contentText)}
                  
                </div>

            </div>
          </div>
        </div>
      </div>
      <section className="section-area section-sp2 ServiceBox1 IconBox1">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary arhide">
              {t("brandmaserati.servicessubhead")}
            </h6>
            <h2 class="title mb-0">{t("brandmaserati.serviceshead")}</h2>
            <p>{t("brandmaserati.servicescontent")}</p>
          </div>
          
          <BrandServices brname={'maserati'} />

        </div>
      </section>
      <section className="section-area BrandFaq mbg1">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary">{t('header-area.home')}</h6>
            <h2 class="title mb-0">{t('header-area.faq')}</h2>
            <p>
            {t('header-area.faqtext')}
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("brandmaserati.question1")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("brandmaserati.answer1")}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("brandmaserati.question2")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("brandmaserati.answer2")}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("brandmaserati.question3")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("brandmaserati.answer3")}</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("brandmaserati.question4")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">{t("brandmaserati.answer2")}</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <ScheduleServiceSection />
      <TestimonialSection />
      <OurBrandsLayout />
      <InstagramBox />
      <Footer />
    </>
  );
}

export default Maserati;
