import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import Images
import icon1 from "../../../images/icon/contact/skin2/icon1.png"
import icon2 from "../../../images/icon/contact/skin2/icon2.png"
import icon3 from "../../../images/icon/contact/skin2/icon3.png"

function WidgetContact() {
	
	const { t } = useTranslation();
		return(
			<>
				<div className="widget">
					<h5 className="widget-title">{t('contactwidget.title')}</h5>
					<ul className="contact-infolist">
						<li>
							<img src={icon1} alt=""/>
							<h6 className="contact-title">{t('contactwidget.number')}</h6>
							<a class="artright" dir="ltr" href="tel:+966 55 783 5647
">+966 55 783 5647
</a>
						</li>
						<li>
							<img src={icon2} alt=""/>
							<h6 className="contact-title">{t('contactwidget.email')}</h6>
							<a href="mailto:hello@swissauto.me">hello@swissauto.me</a>
						</li>
						<li>
							<img src={icon3} alt=""/>
							<h6 className="contact-title">{t('contactwidget.address')}</h6>
							<p>{t('contactwidget.addresstext')}</p>
						</li>
					</ul>
				</div>
				
			</>
		);
}

export default WidgetContact;