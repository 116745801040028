import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// Images

import covervid from "../../../images/swissmain.mp4";
import posterImage from "../../../images/banner/bnr2.jpg";

const HeroMainRow = (props) => {

  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="DiagonalRowWrap">
          <div className="container-fluid p-0 m-0">
            <div className="row m-0 p-0">
              <div className="col-md-3 pd-0 row1">
                <div className="DiBox1">
                  <div className="DiBox1T1">{t('homeHeroMainRow.box1-title')}</div>
                  <div className="DiBox1T2">
                  {t('homeHeroMainRow.box1-sub-text')}
                  </div>
                  <Link class="DiBox1T3" to="/services">
                  {t('homeHeroMainRow.box-button')}
                  </Link>
                </div>
              </div>
              <div className="col-md-3 pd-0 row2">
                <div className="DiBox1">
                  <div className="DiBox1T1">{t('homeHeroMainRow.box2-title')}</div>
                  <div className="DiBox1T2">
                  {t('homeHeroMainRow.box2-sub-text')}
                  </div>
                  <Link class="DiBox1T3" to="/services">
                  {t('homeHeroMainRow.box-button')}
                  </Link>
                </div>
              </div>
              <div className="col-md-3 pd-0 row3">
                <div className="DiBox1">
                  <div className="DiBox1T1">{t('homeHeroMainRow.box3-title')}</div>
                  <div className="DiBox1T2">
                  {t('homeHeroMainRow.box3-sub-text')}
                  </div>
                  <Link class="DiBox1T3" to="/services">
                  {t('homeHeroMainRow.box-button')}
                  </Link>
                </div>
              </div>
              <div className="col-md-3 pd-0 row4">
                <div className="DiBox1">
                  <div className="DiBox1T1">{t('homeHeroMainRow.box4-title')}</div>
                  <div className="DiBox1T2">
                  {t('homeHeroMainRow.box4-sub-text')}
                  </div>
                  <Link class="DiBox1T3" to="/services">
                  {t('homeHeroMainRow.box-button')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroMainRow;
