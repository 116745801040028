import React, {Component, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import useFetchPageInfo from '../../lib/useFetchPageInfo';
// Layout
import Header from "../../layout/header";
import Footer from "../../layout/footer";

import ChooseUsSection from "../../elements/choose-us";
import ScheduleServiceSection from "../../elements/schedule-service";
import TestimonialSection from "../../elements/testimonial";
import ServicesSection from "../../elements/services-1";

// Elements
import WidgetBrochure from "../../elements/widget/widget-brochure";
import WidgetHelp from "../../elements/widget/widget-help";

// Images
import bnrImg from "../../../images/cover.jpg";
import Pic3 from "../../../images/service-section/pic3.jpg";
import Pic6 from "../../../images/service-section/pic6.jpg";
import axios from 'axios';
import { Helmet } from 'react-helmet';

function OurPackeges() {

	const { t } = useTranslation();

	const { pdata, loading, error } = useFetchPageInfo('packages');

	const titleText = pdata?.data?.pageBy?.translation?.title || t("header-area.ourpackage");	
	const contentText = pdata?.data?.pageBy?.translation?.content || '<div className=\"mb-10 ContentBox2\"><\/div>';
	const metaText = pdata?.data?.pageBy?.translation?.seo?.title || '';
  	const metaDesc= pdata?.data?.pageBy?.translation?.seo?.metaDesc || '';


	if (loading) return (<><div className="loader-container"></div></>);
	if (error) return <p>Error: {error.message}</p>;

		return (
			<>
				<Header seotitle={metaText} seodesc={metaDesc} />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>
									{/* Title */}
									{titleText}
									</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>{t('header-area.home')}</Link></li>
										<li className="breadcrumb-item active" aria-current="page">{t('header-area.ourpackage')}</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="section-area section-sp1 bg-white">
						<div className="container">
							<div className="row">
								<div className="col-lg-4 col-md-5">
									<aside className="sticky-top pb-1">
										
										<div className="widget">
											<ul className="service-menu">
												<li><Link to="/packages/service-contract"><span>{t('packages.package3')}</span><i className="fa fa-angle-right"></i> </Link></li>
												<li><Link to="/packages/major-service"><span>{t('packages.package1')}</span><i className="fa fa-angle-right"></i></Link></li>
												<li><Link to="/packages/minor-service"><span>{t('packages.package2')}</span><i className="fa fa-angle-right"></i></Link></li>
											</ul>
										</div>
										
										<WidgetBrochure />
										
										<WidgetHelp />
										
									</aside>
								</div>
								
								
								{/* Main Content */}
								{parse(contentText)}



							</div>
						</div>
					</div>
					
				
				</div>
                <ScheduleServiceSection/>

                <ChooseUsSection/>
                <ServicesSection/>
				<TestimonialSection/>
				<Footer />
				
			</>
		);
}

export default OurPackeges;