import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';



const BrandServices = (props) => {
    let brname = props.brname;
  const { t } = useTranslation();
  return (
    <>
      <div className="SerGridWrap">
              <div className="row mb-10">
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service1">&nbsp;</span>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/mechanical-repair/axle-drift-shaft-repair">{t(brname)} {t('header-area.axle')}</Link></h5>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service2">&nbsp; </span>
                        </span>
                        {/* <span>+</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/mechanical-repair/brake-repair">{t(brname)} {t('header-area.brake')}</Link></h5>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service6">&nbsp;</span>
                        </span>
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/mechanical-repair/suspension-repair">{t(brname)} {t('header-area.suspension')}</Link></h5>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service7">&nbsp;</span>
                        </span>
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/mechanical-repair/transmission-repair">{t(brname)} {t('header-area.transmission')}</Link></h5>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service26">&nbsp;</span>
                        </span>
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/mechanical-repair/engine-cooling-system-repair">{t(brname)} {t('header-area.engilecool')}</Link></h5>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service8">&nbsp;</span>
                        </span>
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/electrical-repair/ac-repair">{t(brname)} {t('header-area.acrepair')}</Link></h5>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service3">&nbsp;</span>
                        </span>
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/electrical-repair/engine-control-unit-repair">{t(brname)} {t('header-area.enginecontrol')}</Link></h5>
                  </div>
                </div>
                
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service9">&nbsp;</span>
                        </span>
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/electrical-repair/camera-radar-calibration">{t(brname)} {t('header-area.camera')}</Link></h5>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service10">&nbsp;</span>
                        </span>
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/electrical-repair/car-software-repair-programming">{t(brname)} {t('header-area.carsoft')}</Link></h5>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service15">&nbsp;</span>
                        </span>
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/body-shop/smart-body-repair">{t(brname)} {t('header-area.smartbody')}</Link></h5>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service19">&nbsp;</span>
                        </span>
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/body-shop/ceramic-coating">{t(brname)} {t('header-area.ceramic')}</Link></h5>
                  </div>
                </div>
                <div className="col-xl-4 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                        <span class="serviceicon service21">&nbsp;</span>
                        </span>
                      </h2>
                    </div>
                    <h5 className="counter-text"><Link to="/services/body-shop/paint-protection-film">{t(brname)} {t('header-area.paint')}</Link></h5>
                  </div>
                </div>
                
              </div>
            </div>
    </>
  );
};

export default BrandServices;
