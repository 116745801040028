import React from 'react'
import Thankyou from '../elements/widget/ThankyouPage'

const ThankyouPage = () => {
  return (
    <div>
        <Thankyou/>
    </div>
  )
}

export default ThankyouPage