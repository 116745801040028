import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import parse from 'html-react-parser';
import useFetchServicesInfo from "../../../lib/useFetchServicesInfo";
// Layout
import Header from "../../../layout/header";
import Footer from "../../../layout/footer";

// Elements
import WidgetBrochure from "../../../elements/widget/widget-brochure";
import WidgetHelp from "../../../elements/widget/widget-help";
import ScheduleServiceSection from "../../../elements/schedule-service";
import TestimonialSection from "../../../elements/testimonial";
import ChooseUsSection from "../../../elements/choose-us";
import ServicesSection from "../../../elements/services-1";

// Images
import bnrImg from "../../../../images/services/PaintJob.webp";
import serviceSectionPic3 from "../../../../images/service-section/pic3.jpg";
import icon1 from "../../../../images/icon/services/professional-customer-support.png";
import icon2 from "../../../../images/icon/services/quality-products.png";
import icon3 from "../../../../images/icon/services/turnaround-time.png";

function BreakCaliperPainting() {

  const { t } = useTranslation();
  const { pdata, loading, error } = useFetchServicesInfo('brake-caliper-painting');

  const titleText = pdata?.data?.serviceBy?.translation?.title || t("header-area.brakecaliper");
  const contentText = pdata?.data?.serviceBy?.translation?.content || '<div className=\"mb-10 ContentBox2\"><\/div>';
  const metaText = pdata?.data?.serviceBy?.translation?.seo?.title || '';
  const metaDesc= pdata?.data?.serviceBy?.translation?.seo?.metaDesc || '';


  if (loading) return (<><div className="loader-container"></div></>);
  if (error) return <p>Error: {error.message}</p>;

    return (
      <>
        <Header seotitle={metaText} seodesc={metaDesc} />

          <div className="page-content bg-white">
            <div
              className="page-banner ovbl-dark"
              style={{ backgroundImage: "url(" + bnrImg + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>
                    <span>{titleText}</span>
                  </h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <i className="las la-home"></i>{t('header-area.home')}
                        </Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                          {t('header-area.brakecaliper')}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div className="section-area section-sp1 bg-white">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-5">
                    <aside className="sticky-top pb-1">
                      <WidgetBrochure />

                      <WidgetHelp />
                    </aside>
                  </div>
                  
                  {/* Main Content */}
                  {parse(contentText)}
                  

                </div>
              </div>
            </div>
          </div>
          <section className="section-area section-sp2 ServiceBox1">
            <div className="container">
              <div class="heading-bx text-center">
                <h6 class="title-ext text-primary arhide">{t('BreakCaliperPainting.benefitsHead')}</h6>
                <h2 class="title mb-0">
                    {t('BreakCaliperPainting.bsubtext')}
                </h2>
              </div>
              <div className="SerGridWrap">
                <div className="row mb-10 justify-content-center">
                  <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                    <div className="counter-style-1">
                      <div className="text-secondry">
                        <h2 className="text-primary">
                          <span className="counter">
                            <img src={icon1} alt={t("BreakCaliperPainting.benefitslist1")}/>
                          </span>
                        </h2>
                      </div>
                      <h5 className="counter-text">
                        {t('BreakCaliperPainting.benefitslist1')}
                      </h5>
                      <p>
                        {t('BreakCaliperPainting.benefitslistText1')}
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                    <div className="counter-style-1">
                      <div className="text-secondry">
                        <h2 className="text-primary">
                          <span className="counter">
                            <img src={icon2} alt={t("BreakCaliperPainting.benefitslist2")}/>
                          </span>
                        </h2>
                      </div>
                      <h5 className="counter-text">{t('BreakCaliperPainting.benefitslist2')}</h5>
                      <p>
                          {t('BreakCaliperPainting.benefitslistText2')}
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12 col-12 SerGrid1">
                    <div className="counter-style-1">
                      <div className="text-secondry">
                        <h2 className="text-primary">
                          <span className="counter">
                            <img src={icon3} alt={t("BreakCaliperPainting.benefitslist3")}/>
                          </span>
                        </h2>
                      </div>
                      <h5 className="counter-text">{t('BreakCaliperPainting.benefitslist3')}</h5>
                      <p>
                          {t('BreakCaliperPainting.benefitslistText3')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-area BrandFaq">
            <div className="container">
              <div class="heading-bx text-center">
                <h6 class="title-ext text-primary">{t('header-area.swiss')}</h6>
                <h2 class="title mb-0">{t('header-area.faq')}</h2>
                <p>
                    {t('header-area.faqtext')}
                </p>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Accordion className="accordion ttr-accordion style1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <i className="fa fa-question-circle"></i>
                            {t('BreakCaliperPainting.question1')}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                            {t('BreakCaliperPainting.answer1')}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <i className="fa fa-question-circle"></i>
                            {t('BreakCaliperPainting.question2')}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                            {t('BreakCaliperPainting.answer2')}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="col-lg-6">
                  <Accordion className="accordion ttr-accordion style1">
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <i className="fa fa-question-circle"></i>
                            {t('BreakCaliperPainting.question3')}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p className="mb-0">
                            {t('BreakCaliperPainting.answer3')}
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </section>

          <ScheduleServiceSection />
          <TestimonialSection />
          <ChooseUsSection />
          <ServicesSection />
          <Footer />
      </>
    );

}

export default BreakCaliperPainting;
