import React, {Component, useState} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import WidgetContact from "../elements/widget/widget-contact";

// Images
import bnrImg from "../../images/banner/bnr2.jpg";
import JobApplicationForm from '../elements/job-app';

function JobCareer() {

	const { t } = useTranslation();
	
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>{t('career.page-title')}</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									<ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>{t('header-area.home')}</Link></li>
										<li className="breadcrumb-item active" aria-current="page">{t('career.page-title')}</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
					
					<div className="section-area bg-gray section-sp1">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-7 mb-md-20">
									<div className="heading-bx displayNone">
										<h6 className="title-ext text-primary">{t('career.page-title')}</h6>
										<h2 className="title mb-0">{t('career.sub-title')}</h2>
										<p className="head-px2">{t('career.sub-text')}</p>
									</div>
									<div className="row">
										<div className="col-md-12 mb-30 displayNone">
											<div className="job-career-box">
												<h4>{t('career.job-title')}</h4>
												<p><strong>{t('career.position')}</strong> {t('career.positiontext')}</p>
												<p><strong>{t('career.exp')}</strong> {t('career.exptext')}</p>
												<p><strong>{t('career.loc')}</strong> {t('career.loctext')}</p>
												<p><strong>{t('career.quali')}</strong> {t('career.qualitext')}</p>
												<p><strong>{t('career.jobdesc')}</strong> {t('career.jobdesctext')}</p>
												<p><strong>{t('career.key')}</strong> {t('career.keytext')}</p>
												<ul className="list-check">
													<li>{t('career.list1')}</li>
													<li>{t('career.list2')}</li>
													<li>{t('career.list3')}</li>
													<li>{t('career.list4')}</li>
													<li>{t('career.list5')}</li>
													<li>{t('career.list6')}</li>
												</ul>
												<p><strong>{t('career.skills')}</strong> {t('career.skillstext')}</p>
												<a href="#crform" className="btn btn-primary mt-10">{t('career.apply')}</a>
											</div>
										</div>
										
										<div id='crform'>
											<JobApplicationForm />
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-5 mb-20">
									<aside className="sticky-top">
										
										
										<WidgetContact />
										
									</aside>
								</div>
							</div>
							
						</div>
					</div>
				
				</div>
				
				<Footer />
				
			</>
		);
}

export default JobCareer;