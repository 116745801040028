import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import parse from 'html-react-parser';
import useFetchAboutInfo from "../lib/useFetchAboutInfo";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import AboutUsTwoSection from "../elements/about-us-2";
import ServicesSection from "../elements/services-1";
import ScheduleServiceSection from "../elements/schedule-service";
import TestimonialSection from "../elements/testimonial";
import LatestBlogSection from "../elements/latest-blog-slider";

// Images
import bnrImg from "../../images/banner/aboutus.jpg";

import icon1 from "../../images/icon/services/engine-repair.png";
import icon2 from "../../images/icon/services/experienced-technicians.png";
import icon3 from "../../images/icon/services/spare-parts1.png";
import icon4 from "../../images/icon/services/multi-cultural-team.png";
import icon5 from "../../images/icon/services/crm-system.png";
import icon6 from "../../images/icon/services/spare-parts.png";
import icon7 from "../../images/icon/services/easy-access.png";
import icon8 from "../../images/icon/services/security-system.png";
import icon9 from "../../images/icon/services/workshop.png";
import icon10 from "../../images/icon/services/workshop.png";
import icon11 from "../../images/icon/services/prayer-room.png";
import icon12 from "../../images/icon/services/ac-wifi.png";
import icon13 from "../../images/icon/services/service-advisors.png";

import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Helmet } from "react-helmet";

function AboutUs() {

  const { t } = useTranslation();
  const { pdata, loading, error } = useFetchAboutInfo('about-us');


  const titleText = pdata?.data?.pageBy?.translation?.title || t('aboutpage.about-title');
  const contentText = pdata?.data?.pageBy?.translation?.content || '<div className=\"AbtBx1\"><\/div>';
  const contentMission = pdata?.data?.pageBy?.translation?.ourmission?.ourmission || '<div className=\" AbtBx2\"><\/div>';
  const contentVision = pdata?.data?.pageBy?.translation?.ourvision?.vision || '<div className=\"AbtBx1\"><\/div>';
  const metaText = pdata?.data?.pageBy?.translation?.seo?.title || '';
  const metaDesc = pdata?.data?.pageBy?.translation?.seo?.metaDesc || '';

  if (loading) return (<><div className="loader-container"></div></>);
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>

      <Header seotitle={metaText} seodesc={metaDesc} />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>{titleText}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="las la-home"></i>{t('header-area.home')}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t('aboutpage.about-title')}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* Main Content */}
        {parse(contentText)}

        {/* Our Mission */}
        {parse(contentMission)}

        {/* Our Vision */}
        {parse(contentVision)}

        <section className="section-area section-sp2 ServiceBox1">
          <div className="container">
            <div class="heading-bx text-center">
              <h6 class="title-ext text-primary">{t('aboutpage.facility')}</h6>
              <h2 class="title mb-0">{t('aboutpage.facility-head')}</h2>
              <p>
                {t('aboutpage.facility-subtext')}
              </p>
            </div>
            <div className="SerGridWrap">
              <div className="row mb-10">
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon1} alt={t("aboutpage.list1")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list1')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon2} alt={t("aboutpage.list2")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list2')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon3} alt={t("aboutpage.list3")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list3')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon4} alt={t("aboutpage.list4")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list4')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon5} alt={t("aboutpage.list5")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list5')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon6} alt={t("aboutpage.list6")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list6')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon7} alt={t("aboutpage.list7")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list7')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon8} alt={t("aboutpage.list8")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list8')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon9} alt={t("aboutpage.list9")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list9')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon10} alt={t("aboutpage.list10")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list10')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon11} alt={t("aboutpage.list11")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list11')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon12} alt={t("aboutpage.list12")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list12')}</h5>
                  </div>
                </div>
                <div className="col-xl-6 col-sm-12 col-12">
                  <div className="counter-style-1">
                    <div className="text-secondry">
                      <h2 className="text-primary">
                        <span className="counter">
                          <img src={icon13} alt={t("aboutpage.list13")}/>
                        </span>
                        {/* <span>K</span> */}
                      </h2>
                    </div>
                    <h5 className="counter-text">{t('aboutpage.list13')}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>



        <TestimonialSection />
        <ScheduleServiceSection />
      </div>

      <Footer />
    </>
  );
}

export default AboutUs;