import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import i18next from "i18next";
import parse from 'html-react-parser';

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import WidgetSearch from "../elements/widget/widget-search";
import WidgetRecentPosts from "../elements/widget/widget-recent-posts";
import WidgetGallery from "../elements/widget/widget-gallery";
import WidgetTag from "../elements/widget/widget-tag";

// Images
import bnrImg from "../../images/banner/Blogs.jpg";
import blogGridPic1 from "../../images/blog/grid/pic3.jpg";

import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

// Define the number of posts per page
const POSTS_PER_PAGE = 10;

const BlogPosts = () => {

  const { t } = useTranslation();
  
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const currentLanguage = i18next.language;

  // GraphQL query as a string
  const GET_BLOG_POSTS_QUERY = `
    query MyQuery2 {
      posts(where: {language: EN}) {
        nodes {
          slug
          translation(language: ${currentLanguage.toUpperCase()}) {
            title
            excerpt
            date
            status
            featuredImage {
              node {
                mediaItemUrl
                altText
              }
            }
          }
        }
      }
    }
  `;

  // Fetch the blog posts using Axios and the GraphQL query
  const fetchPosts = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.post("https://cms.swissauto.me/graphql", {
        query: GET_BLOG_POSTS_QUERY,
      });

      const fetchedPosts = response.data.data.posts.nodes;
      //const fetchedPosts = response.data.data;
      setPosts(fetchedPosts);

      // Set the total number of pages
      const totalFetchedPages = Math.ceil(fetchedPosts.length / POSTS_PER_PAGE);
      setTotalPages(totalFetchedPages);

    } catch (err) {
      setError("Failed to fetch posts");
    } finally {
      setLoading(false);
    }
  };

  // Fetch posts whenever the current page changes
  useEffect(() => {
    fetchPosts(currentPage);
  }, [currentPage]);

  // Handle pagination (change page)
  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  // Paginate posts for the current page
  const paginatedPosts = posts.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  );

  return (
    <>
      {loading ? (<></>) : 
              error ? (<p>{error}</p>) : (
        <>
          <Header />

          <div className="page-content bg-gray">
					
            <div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1><span>{t("header-area.blogs")}</span></h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>{t('header-area.home')}</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">{t('header-area.blogs')}</li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <section className="section-area section-sp2">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12 col-lg-12 col-xl-12 mb-30 mb-md-50">
                          <div className="row">
                                {/*
                                  {paginatedPosts.map((post, index) => (
                                    <div key={index} className="post">
                                      <h2>{post.translation.title}</h2>
                                      <p>{post.translation.excerpt}</p>              
                                      <p>Date: {new Date(post.translation.date).toLocaleDateString()}</p>
                                      <Link to={"/"+post.slug}><i className="las la-home"></i>Read More</Link>
                                    </div>
                                  ))}
                                */}
                                  {/* Blog List */}
                          
                                  {paginatedPosts.map((post, index) => (
                                          ((post?.translation) ? ( //check for null translation
                                            ((post?.translation.status == 'publish') ? ( //check for null translation
                                              <div key={index} className="col-6 mb-30">
                                                    
                                                          <div className="blog-card blog-list list-large style-1 bg-white shadow ht100">
                                                              <div className="post-media">
                                                                <Link to={"/blog-details/"+post.slug}>
                                                                    <img 
                                                                        src={(post?.translation?.featuredImage) ? post?.translation?.featuredImage?.node?.mediaItemUrl : blogGridPic1 } 
                                                                        alt={(post?.translation?.featuredImage?.node?.altText) ? post?.translation?.featuredImage?.node?.altText : post.translation.title }/>
                                                                </Link>
                                                              </div>
                                                              <div className="post-info cst-post-info">
                                                                <h4 className="post-title"><Link to={"/blog-details/"+post.slug}>{post?.translation?.title  ? post?.translation?.title : ""}</Link></h4>
                                                                <div className="post-content">
                                                                  {post?.translation?.excerpt ? parse(post?.translation?.excerpt) : ""}                                              
                                                                </div>
                                                                <Link className="SerT2 btn btn-primary" to={"/blog-details/"+post.slug}>Read More</Link>
                                                              </div>
                                                            </div>
                                                    
                                              </div>
                                              ) : "")
                                          ) : "")
                                   ))}
                                    {/* Blog List */}
                          </div>
                      </div>

                      <div className="col-lg-12">
                          <div className="pagination-bx text-center clearfix displayNone">
                            {/*<ul className="pagination">
                              <li className="previous">
                                <a href="/react/blog-list-sidebar">Prev</a>
                              </li>                              
                            </ul>
                            <ul className="pagination">                              
                              <li className="next">
                                <a href="/react/blog-list-sidebar">Next</a>
                              </li>
                            </ul>
                            */}

                            <div className="pagination">
                              <button className="previous" onClick={() => handlePageChange(currentPage - 1)}>
                                Prev
                              </button>
                              <span>Page {currentPage} of {totalPages}</span>
                              <button className="next" onClick={() => handlePageChange(currentPage + 1)}>
                                Next
                              </button>
                            </div>

                          </div>
                        </div>
                      
                      
                  </div>
              </div>
            </section>
                  
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default BlogPosts;
