import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

// Images
import logo2 from '../../images/whitelogo.png';
import icon1 from '../../images/icon/contact/icon1.png';
import icon2 from '../../images/icon/contact/icon2.png';
import icon3 from '../../images/icon/contact/icon3.png';
import recentBlogPic1 from '../../images/blog/recent-blog/pic1.jpg';
import recentBlogPic2 from '../../images/blog/recent-blog/pic2.jpg';
import homeicon from '../../images/icon/footer/home.png';
import callicon from '../../images/icon/footer/call-us.png';
import quoteicon from '../../images/icon/footer/get-a-quote.png';
import servicesicon from '../../images/icon/footer/services.png';
import whatsappicon from '../../images/icon/footer/whatsapp.png';


import axios from 'axios';

function AboutSection() {

	const { t } = useTranslation();
	const [content, setContent] = useState([]);
  	const [currentPage, setCurrentPage] = useState(1);

	const currentLanguage = i18next.language; // "en" or "ar"

	useEffect(() => {
		axios.get(`https://swiss-backend.vercel.app/api/blogs/blog?page=${currentPage}`).then((response) => {
		setContent(response.data.data);
		//console.log(response.data.data);
		});
	}, [currentPage]);

		return(
			<>
				
				<footer className="footer">
				<div class="halo-sticky-toolbar-mobile mob-only">
						<div class="bottom-bar">
							<ul class="bottom-bar--list">
								<li>
									<Link to="/">
										<div class="icon">
										<img src={homeicon} alt={t('imagealttext.swisslogo')} />
										</div>
										<div class="text">{t('m-footer-section.home')}</div>
									</Link>
								</li>
								<li>
									<Link to="/booking">
										<div class="icon">
										<img src={quoteicon} alt="Click to book an appointment" />
										</div>
										<div class="text">{t('m-footer-section.get-quote')}</div>
									</Link>
								</li>
								<li>
									<Link to="/services">
										<div class="icon">
										<img src={servicesicon} alt="Click to know all the services" />
										</div>
										<div class="text">{t('m-footer-section.services')}</div>
									</Link>
								</li>
								<li>
									<a href="tel:+966 55 783 5647
">
										<div class="icon">
										<img src={callicon} alt="Click to Call Us" />
										</div>
										<div class="text">{t('m-footer-section.call')}</div>
									</a>
								</li>
								<li>
									<a href="https://wa.me/966557835647?text=Hi%2C%20Swiss%20Auto%20Services">
										<div class="icon">
										<img src={whatsappicon} alt="Click to Message on Whatsapp" />
										</div>
										<div class="text">{t('m-footer-section.whatsapp')}</div>
									</a>
								</li>
							</ul>
						</div>
					</div>
					{/* <div className="footer-info bg-primary">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="footer-logo">
										<img src={logo2} alt=""/> 
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell"><img src={icon3} alt=""/></span> 
										</div>
										<div className="icon-content">
											<p>2005 Stokes Isle Apt. 896, Venaville 10010, USA</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell"><img src={icon1} alt=""/></span> 
										</div>
										<div className="icon-content">
											<p>+001 123 456 790 <br/>(02) 3424 44 00</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell"><img src={icon2} alt=""/></span> 
										</div>
										<div className="icon-content">
											<p>info@yourdomain.com <br/>example@support.com</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> */}
					<div className="footer-top bt0">
						<div className="container">
							<div className="row">
								<div className="col-xl-4 col-md-6">
									<div className="widget footer_widget">
										<h5 className="footer-title">{t('footer-section.about-title')}</h5>
										<p className="mb-20">{t('footer-section.excert')}</p>
										<div className="ft-content">
											<i className="fa fa-phone"></i>
											<span>{t('footer-section.talk')}</span>
											<h4 class="artright" dir="ltr">{t('footer-section.phoneno')}
 </h4>
										</div>
									</div>
								</div>
								<div className="col-xl-4 col-md-6">
									<div className="widget widget-link-2">
										<h5 className="footer-title">{t('footer-section.solution-title')}</h5>
										<ul>
											<li><Link to="/">{t('footer-section.home')}</Link></li>
											<li><Link to="/ourbrands">{t('footer-section.brands')}</Link></li>
											<li><Link to="/services">{t('footer-section.services')}</Link></li>
											<li><Link to="/packages">{t('footer-section.packages')}</Link></li>
											<li><Link to="/aboutus">{t('footer-section.who')}</Link></li>
											<li><Link to="/careers">{t('footer-section.careers')}</Link></li>
											<li><Link to="/booking">{t('footer-section.booking')}</Link></li>
											<li><Link to="/blogs">{t('header-area.blogs')}</Link></li>
											<li><Link to="/services">{t('footer-section.ourservices')}</Link></li>
											<li><Link to="/contact">{t('footer-section.contactus')}</Link></li>
										</ul>							
									</div>
								</div>
								
								<div className="col-xl-4 col-md-6">
									<div className="widget widget_info">
										<h5 className="footer-title">{t('footer-section.subscribe')}</h5>
										<p className="mb-20">{t('footer-section.sub-text')}</p>
										<form className="subscribe-form subscription-form mb-30">
											<div className="ajax-message"></div>
											<div className="input-group">
												<input name="email" required="required" className="form-control" placeholder={t('footer-section.email')} type="email"/>
												<div className="input-group-append">
													<button name="submit" value="Submit" type="submit" className="btn btn-block btn-primary radius-sm">{t('footer-section.send')}</button>	
												</div>
											</div>
										</form>
										<ul className="list-inline ft-social-bx">
											<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/swissautosa/" className="btn button-sm"><i className="fa fa-facebook"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/swissautosa/" className="btn button-sm"><i className="fa fa-instagram"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCIDHmzSK6mFQykqrG_SjVig" className="btn button-sm"><i className="fa fa-youtube"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://wa.me/966557835647?text=Hi%2C%20Swiss%20Auto%20Services" className="btn button-sm"><i className="fa fa-whatsapp"></i></a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-start">
									<p className="mb-0">{t('footer-section.copyright')}</p>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-end">
									<ul className="widget-link">
										<li><Link to="/">{t('footer-section.home')}</Link></li>
										<li><Link to="/aboutus">{t('footer-section.about')}</Link></li>
										<li><Link to="/services">{t('footer-section.services')}</Link></li>
										<li><Link to="/contact">{t('footer-section.contact')}</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</footer>
				
			</>
		);
}

export default AboutSection;