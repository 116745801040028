import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Images

import covervid from "../../../images/swissmain.mp4";
import posterImage from "../../../images/banner/bnr2.jpg";
import SwissBrands from "./Swiss-Brands";

const OurBrandsLayout = (props) => {

  const { t } = useTranslation();
  return (
    <>
      <div className="OurBrandsRow section-sp2">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary">{t('home-brands-section.partner')}</h6>
            <h2 class="title mb-0">{t('home-brands-section.title')}</h2>
            <p>{t('home-brands-section.sub-title')}</p>
          </div>
          <SwissBrands />
        </div>
      </div>
    </>
  );
};

export default OurBrandsLayout;
