import React, {Component} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import i18next from "i18next";
import parse from 'html-react-parser';

import { useTranslation } from "react-i18next";
// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import WidgetRecentPosts from "../elements/widget/widget-recent-posts";

// Images
import bnrImg from "../../images/banner/Blogs.jpg";
import testPic1 from "../../images/testimonials/pic1.jpg";
import defaultPic1 from "../../images/blog/default/pic1.jpg";
import galleryPic2 from "../../images/gallery/pic2.jpg";
import galleryPic5 from "../../images/gallery/pic5.jpg";
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { EditorState, convertFromRaw, Editor } from "draft-js";
import WidgetBrochure from '../elements/widget/widget-brochure';
import useFetchBlogInfo from '../lib/useFetchBlogInfo';

function BlogDetails() {
	const history = useHistory();
	const { t } = useTranslation();
	const { blogUrl } = useParams();
	const { pdata, loading, error } = useFetchBlogInfo(blogUrl);

	
  //console.log(pdata);
  const titleText = pdata?.data?.postBy?.translation?.title || t("header-area.blogs");
  const imageUrl = pdata?.data?.postBy?.translation?.featuredImage?.node?.mediaItemUrl || defaultPic1;
  const altText = pdata?.data?.postBy?.translation?.featuredImage?.node?.altText || t("header-area.blogs");
  const contentText = pdata?.data?.postBy?.translation?.content || '<div className=\"mb-10 ContentBox2\"><\/div>';
  const metaText = pdata?.data?.postBy?.translation?.seo?.title || '';
const metaDesc= pdata?.data?.postBy?.translation?.seo?.metaDesc || '';

  

  //if (loading) return (<><Header /><div className="page-content bg-white"><div className="page-banner intpage"></div><div className="loader-container"><p>Loading...</p></div></div></>);
  //if (error) return <p>Error: {error.message}</p>;

	return (
			<>
			{loading ? (<></>) : 
              error ? (<p>{error}</p>) : (
				<>
					{((pdata?.data?.postBy) ? ( //check for null translation
						((pdata?.data?.postBy?.translation?.status == 'publish') ? ( //check for null translation
							<>
							<Header seotitle={metaText} seodesc={metaDesc} />
							
							<div className="page-content bg-white">
									<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
										<div className="container">
											<div className="page-banner-entry text-center">
												<h1>{titleText}</h1>
												<nav aria-label="breadcrumb" className="breadcrumb-row">
													<ul className="breadcrumb">
														<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>{t('header-area.home')}</Link></li>
														<li className="breadcrumb-item active" aria-current="page">{t("header-area.blogs")}</li>
													</ul>
												</nav>
											</div>
										</div>
									</div>
									<section className="section-area section-sp2 bg-white">
											<div className="container">
												<div className="row">
													<div className="col-md-12 col-lg-8 col-xl-8 mb-30 mb-md-60">
													<div className="blog-lg blog-single">
															<div className="action-box blog-lg">
																<img src={imageUrl} alt={altText}/>
															</div>
															<div className="info-bx">
																<ul className="post-meta">
																	<li className="date"><i class="las la-calendar"></i> {pdata?.data?.postBy?.translation?.date ? new Date(pdata?.data?.postBy?.translation?.date).toLocaleDateString('en-US') : "No date available"}</li>
																</ul>
																
																{parse(contentText)}
																
															</div>
														</div>
													</div>
													<div className="col-md-12 col-lg-4 col-xl-4 mb-30">
														<aside className="side-bar sticky-top aside-bx">
															
															<WidgetBrochure />

															{/*<WidgetRecentPosts content={content} />*/}
															
															
															
														</aside>
													</div>
												</div>
											</div>
										</section>



							</div>

							<Footer />
							</>
						) : history.push("/error"))
					) : history.push("/error"))}
				
				</>
			  )}
				
				
				
			</>
		);
}

export default BlogDetails;