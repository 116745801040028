import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import parse from 'html-react-parser';
import useFetchPageInfo from '../../lib/useFetchPageInfo';
// Layout
import Header from "../../layout/header";
import Footer from "../../layout/footer";

// Elements
import WidgetBrochure from "../../elements/widget/widget-brochure";
import WidgetHelp from "../../elements/widget/widget-help";

// Images
import bnrImg from "../../../images/services/SmartBodyRepair.webp";
import Pic1 from "../../../images/service-section/pic1.jpg";
import axios from "axios";
import { Helmet } from "react-helmet";

function MinorServices() {
  const { t } = useTranslation();

	const { pdata, loading, error } = useFetchPageInfo('minor-service');

	const titleText = pdata?.data?.pageBy?.translation?.title || t("header-area.minorservice");	
	const contentText = pdata?.data?.pageBy?.translation?.content || '<div className=\"mb-10 ContentBox2\"><\/div>';
  const metaText = pdata?.data?.pageBy?.translation?.seo?.title || '';
  const metaDesc= pdata?.data?.pageBy?.translation?.seo?.metaDesc || '';


	if (loading) return (<><div className="loader-container"></div></>);
	if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Header seotitle={metaText} seodesc={metaDesc} />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>
                  {/* Title */}
                  {titleText}</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="las la-home"></i>
                      {t("header-area.home")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("header-area.minorservice")}
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <div className="section-area section-sp1 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <aside className="sticky-top pb-1">
                  <WidgetBrochure />

                  <WidgetHelp />
                </aside>
              </div>
              
              
              {/* Main Content */}
							{parse(contentText)}



            </div>
          </div>
        </div>
      </div>

      <section className="section-area BrandFaq">
        <div className="container">
          <div class="heading-bx text-center">
            <h6 class="title-ext text-primary">{t("header-area.swiss")}</h6>
            <h2 class="title mb-0">{t("header-area.faq")}</h2>
            <p>
            {t("header-area.faqtext")}
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("minorservice.question1")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      T{t("minorservice.answer1")}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("minorservice.question2")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                    {t("minorservice.answer2")}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <Accordion className="accordion ttr-accordion style1">
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <i className="fa fa-question-circle"></i>
                    {t("minorservice.question3")}
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                    {t("minorservice.answer3")}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default MinorServices;
